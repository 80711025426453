import React, { useEffect, useRef } from "react";
import dataImg from "../../assets/images/data-visualization.jpg";

const Stat = ({ value, label, delay }) => {
  const statRef = useRef(null);

  useEffect(() => {
    const observerOptions = {
      threshold: 0.1,
      rootMargin: "0px",
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setTimeout(() => {
            entry.target.classList.add("animate-fade-up");
            entry.target.classList.remove("opacity-0");
          }, delay);
          observer.unobserve(entry.target);
        }
      });
    }, observerOptions);

    if (statRef.current) {
      observer.observe(statRef.current);
    }

    return () => observer.disconnect();
    // eslint-disable-next-line
  }, [delay]);

  return (
    <div ref={statRef} className="opacity-0 text-center">
      <div className="fs-3 fw-bold text-primary mb-2">{value}</div>
      <div className="text-muted small">{label}</div>
    </div>
  );
};

const About = () => {
  const titleRef = useRef(null);
  const contentRef = useRef(null);
  const imageRef = useRef(null);

  useEffect(() => {
    const observerOptions = {
      threshold: 0.1,
      rootMargin: "0px",
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (entry.target === imageRef.current) {
            entry.target.classList.add("animate-slide-in-right");
          } else {
            entry.target.classList.add("animate-fade-up");
          }
          entry.target.classList.remove("opacity-0");
          observer.unobserve(entry.target);
        }
      });
    }, observerOptions);

    if (titleRef.current) {
      observer.observe(titleRef.current);
    }
    if (contentRef.current) {
      observer.observe(contentRef.current);
    }
    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <section id="about" className="py-5 py-md-7 bg-light">
      <div className="container py-4">
        <div className="row g-4 align-items-center">
          <div className="col-lg-6">
            <h2 ref={titleRef} className="opacity-0 fs-1 fw-bold mb-4">
              Crafting Digital Excellence Since 2020
            </h2>

            <div ref={contentRef} className="opacity-0 text-muted">
              <p className="mb-3">
                At MilesCorp, we're passionate about transforming complex
                technical challenges into elegant, functional solutions. Our
                team of seasoned developers, designers, and consultants works
                collaboratively to deliver software that exceeds expectations.
              </p>
              <p className="mb-3">
                We believe in a client-first approach, focusing on understanding
                your business objectives before writing a single line of code.
                This ensures that our solutions not only solve technical
                problems but also drive tangible business value.
              </p>
              <p className="mb-3">
                Our expertise spans across various industries including fintech,
                healthcare, e-commerce, and enterprise solutions, allowing us to
                bring cross-domain insights to every project.
              </p>
            </div>
          </div>

          <div ref={imageRef} className="col-lg-6 opacity-0">
            <div className="position-relative">
              <div
                className="position-absolute"
                style={{
                  top: "-1rem",
                  right: "-1rem",
                  bottom: "-1rem",
                  left: "-1rem",
                  backgroundColor: "rgba(13, 110, 253, 0.05)",
                  borderRadius: "1rem",
                  zIndex: -1,
                }}
              ></div>
              <div className="bg-white rounded shadow-sm overflow-hidden">
                <div className="bg-light w-100" style={{ aspectRatio: "16/9" }}>
                  <img src={dataImg} alt="About Us Data Img" />
                </div>
                <div className="p-4">
                  <h3 className="fs-4 fw-semibold mb-4">Our Expertise</h3>

                  <div className="row row-cols-2 row-cols-md-4 g-4 mt-3">
                    <div className="col">
                      <Stat value="100+" label="Projects Completed" delay={0} />
                    </div>
                    <div className="col">
                      <Stat value="50+" label="Happy Clients" delay={150} />
                    </div>
                    <div className="col">
                      <Stat value="5+" label="Years Experience" delay={300} />
                    </div>
                    <div className="col">
                      <Stat value="24/7" label="Support" delay={450} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
