import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "animate.css";
import TrackVisibility from "react-on-screen";
import contactImg from "../../assets/images/contact-img.svg";

const Contact = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formRef = useRef(null);
  const titleRef = useRef(null);
  const descRef = useRef(null);

  useEffect(() => {
    const observerOptions = {
      threshold: 0.1,
      rootMargin: "0px",
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("animate-fade-up");
          entry.target.classList.remove("opacity-0");
          observer.unobserve(entry.target);
        }
      });
    }, observerOptions);

    if (titleRef.current) {
      observer.observe(titleRef.current);
    }
    if (descRef.current) {
      observer.observe(descRef.current);
    }
    if (formRef.current) {
      observer.observe(formRef.current);
    }

    return () => observer.disconnect();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Simulate form submission
    setTimeout(() => {
      setIsSubmitting(false);
      if (formRef.current) {
        formRef.current.reset();
      }

      // Show Bootstrap toast
      const toastEl = document.getElementById("successToast");
      if (toastEl) {
        const toast = new window.bootstrap.Toast(toastEl);
        toast.show();
      }
    }, 1500);
  };

  return (
    <section
      id="contact"
      className="py-5 py-md-7 bg-white position-relative overflow-hidden"
    >
      <Row>
        <Col
          md={6}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <TrackVisibility>
            {({ isVisible }) => (
              <img
                className={isVisible ? "animate__animated animate__swing" : ""}
                src={contactImg}
                alt="Contact Us"
              />
            )}
          </TrackVisibility>
        </Col>
        <div className="col-md-6">
          {/* Background decoration */}
          <div
            className="position-absolute top-0 end-0 w-50 h-50 bg-primary-light rounded-bottom-start-pill"
            style={{ zIndex: -1 }}
          ></div>
          <div
            className="position-absolute bottom-0 start-0 w-25 h-25 bg-primary-light rounded-top-end-pill"
            style={{ zIndex: -1 }}
          ></div>

          <div className="container py-4">
            <div
              className="mx-auto text-center mb-5"
              style={{ maxWidth: "800px" }}
            >
              <h2
                ref={titleRef}
                className="opacity-0 fs-1 fw-bold mb-3 text-gradient"
              >
                Let's Build Something Amazing Together
              </h2>
              <p ref={descRef} className="opacity-0 text-muted lead">
                Ready to start your next project? Contact us today for a free
                consultation.
              </p>
            </div>

            <div className="mx-auto" style={{ maxWidth: "800px" }}>
              <form
                ref={formRef}
                onSubmit={handleSubmit}
                className="opacity-0 bg-white rounded p-4 p-md-5 shadow-lg border"
              >
                <div className="row g-4 mb-4">
                  <div className="col-md-6">
                    <label htmlFor="name" className="form-label fw-medium">
                      Name
                    </label>
                    <input
                      id="name"
                      type="text"
                      className="form-control form-control-lg border-primary-subtle"
                      required
                    />
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="email" className="form-label fw-medium">
                      Email
                    </label>
                    <input
                      id="email"
                      type="email"
                      className="form-control form-control-lg border-primary-subtle"
                      required
                    />
                  </div>
                </div>

                <div className="mb-4">
                  <label htmlFor="subject" className="form-label fw-medium">
                    Subject
                  </label>
                  <input
                    id="subject"
                    type="text"
                    className="form-control form-control-lg border-primary-subtle"
                  />
                </div>

                <div className="mb-4">
                  <label htmlFor="message" className="form-label fw-medium">
                    Message
                  </label>
                  <textarea
                    id="message"
                    rows={5}
                    className="form-control form-control-lg border-primary-subtle"
                    required
                    style={{ resize: "none" }}
                  ></textarea>
                </div>

                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="btn btn-primary btn-lg w-100 py-3 transition-all hover-lift"
                >
                  {isSubmitting ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm me-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Sending...
                    </>
                  ) : (
                    "Send Message"
                  )}
                </button>
              </form>
            </div>
          </div>

          {/* Bootstrap Toast */}
          <div
            className="position-fixed bottom-0 end-0 p-3"
            style={{ zIndex: 11 }}
          >
            <div
              id="successToast"
              className="toast align-items-center text-white bg-success border-0"
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
            >
              <div className="d-flex">
                <div className="toast-body">
                  <i className="bi bi-check-circle-fill me-2"></i>
                  Message sent! We'll get back to you soon.
                </div>
                <button
                  type="button"
                  className="btn-close btn-close-white me-2 m-auto"
                  data-bs-dismiss="toast"
                  aria-label="Close"
                ></button>
              </div>
            </div>
          </div>
        </div>
      </Row>
    </section>
  );
};

export default Contact;
