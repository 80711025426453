import React, { useEffect, useRef } from "react";
import desktopIcon from "../../assets/icons/desktop-svgrepo-com.svg";

const ServiceCard = ({ title, description, icon, delay }) => {
  const cardRef = useRef(null);

  useEffect(() => {
    const observerOptions = {
      threshold: 0.1,
      rootMargin: "0px",
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setTimeout(() => {
            entry.target.classList.add("animate-fade-up");
            entry.target.classList.remove("opacity-0");
          }, delay);
          observer.unobserve(entry.target);
        }
      });
    }, observerOptions);

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => observer.disconnect();
    // eslint-disable-next-line
  }, [delay]);

  return (
    <div
      ref={cardRef}
      className="bg-white rounded p-4 shadow-sm opacity-0 hover-lift"
    >
      <div
        className="bg-primary-light rounded-circle d-flex align-items-center justify-content-center mb-4 text-primary"
        style={{ width: "56px", height: "56px" }}
      >
        {icon}
      </div>
      <h3 className="fs-4 fw-medium mb-3">{title}</h3>
      <p className="text-muted">{description}</p>
    </div>
  );
};

const Services = () => {
  const titleRef = useRef(null);
  const subtitleRef = useRef(null);

  useEffect(() => {
    const observerOptions = {
      threshold: 0.1,
      rootMargin: "0px",
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("animate-fade-up");
          entry.target.classList.remove("opacity-0");
          observer.unobserve(entry.target);
        }
      });
    }, observerOptions);

    if (titleRef.current) {
      observer.observe(titleRef.current);
    }
    if (subtitleRef.current) {
      observer.observe(subtitleRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <section id="services" className="py-5 py-md-7 bg-white">
      <div className="container py-4">
        <div className="text-center mb-5">
          <h2 ref={titleRef} className="opacity-0 mb-3 display-5 fw-bold">
            Our Services
          </h2>
          <p
            ref={subtitleRef}
            className="opacity-0 text-muted mx-auto"
            style={{ maxWidth: "800px" }}
          >
            We provide comprehensive software development services tailored to
            meet your specific business requirements.
          </p>
        </div>

        <div className="row g-4">
          <div className="col-md-6 col-lg-4">
            <ServiceCard
              title="Web Application Development"
              description="Custom web applications built with the latest technologies, ensuring scalability, performance, and intuitive user experiences."
              icon={
                <svg
                  className="bi"
                  width="28"
                  height="28"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h13A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5v-9zM1.5 3a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-13z" />
                  <path d="M2 4.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1H3v2.5a.5.5 0 0 1-1 0v-3zm12 7a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1 0-1H13V8.5a.5.5 0 0 1 1 0v3z" />
                </svg>
              }
              delay={0}
            />
          </div>

          <div className="col-md-6 col-lg-4">
            <ServiceCard
              title="Mobile App Development"
              description="Native and cross-platform mobile applications that deliver exceptional user experiences across iOS and Android devices."
              icon={
                <svg
                  className="bi"
                  width="28"
                  height="28"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
                  <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                </svg>
              }
              delay={100}
            />
          </div>

          <div className="col-md-6 col-lg-4">
            <ServiceCard
              // title="Cloud Solutions"
              title={"Desktop Development"}
              description="From
              conceptualization to deployment, MilesCorp prioritizes user
              experience, functionality, and scalability, ensuring that every
              desktop application delivers exceptional performance."
              // description="Secure, scalable, and cost-effective cloud infrastructure setups and migrations with AWS, Azure, or Google Cloud."
              icon={
                // <svg
                //   className="bi"
                //   width="28"
                //   height="28"
                //   fill="currentColor"
                //   viewBox="0 0 16 16"
                // >
                //   <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z" />
                // </svg>
                <img src={desktopIcon} alt="desktop svg" width={"60%"} />
              }
              delay={200}
            />
          </div>

          <div className="col-md-6 col-lg-4">
            <ServiceCard
              title="DevOps & CI/CD"
              description="Streamlined development operations with continuous integration and delivery pipelines that accelerate your software releases."
              icon={
                <svg
                  className="bi"
                  width="28"
                  height="28"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
                  />
                  <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                </svg>
              }
              delay={300}
            />
          </div>

          <div className="col-md-6 col-lg-4">
            <ServiceCard
              title="Custom API Development"
              description="Robust and secure RESTful or GraphQL APIs that facilitate seamless integration between applications and services."
              icon={
                <svg
                  className="bi"
                  width="28"
                  height="28"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                  <path d="M6.854 4.646a.5.5 0 0 1 0 .708L4.207 8l2.647 2.646a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 0 1 .708 0zm2.292 0a.5.5 0 0 0 0 .708L11.793 8l-2.647 2.646a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708 0z" />
                </svg>
              }
              delay={400}
            />
          </div>

          <div className="col-md-6 col-lg-4">
            <ServiceCard
              title="Software Consulting"
              description="Expert guidance on technology selection, architecture design, and development processes to optimize your software projects."
              icon={
                <svg
                  className="bi"
                  width="28"
                  height="28"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
                </svg>
              }
              delay={500}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
