import React from "react";
import Banner from "./Banner";
import Contact from "./Contact";
import Services from "./Services";
import OurWork from "./OurWork";
import About from "./About";

const Home = () => {
  return (
    <div>
      <Banner />
      <Services />
      <About />
      <OurWork />
      <Contact />
    </div>
  );
};

export default Home;
