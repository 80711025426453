import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Blogs from "../../assets/files/blogs.json";
import PageNotFound from "../../components/PageNotFound";
import BlogContainer from "./BlogContainer";

const BlogPage = () => {
  const { blogRepr } = useParams();

  const [blog, setBlog] = useState(null);

  useEffect(() => {
    setBlog((Blogs || []).find((blog) => blog.blog_repr === blogRepr));
  }, [blogRepr]);

  console.log(blog);

  return blog ? <BlogContainer blog={blog} /> : <PageNotFound />;
};

export default BlogPage;
