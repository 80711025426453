import React, { useEffect, useState } from "react";
import "../../blog.css";
import Blogs from "../../assets/files/blogs.json";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { Helmet } from "react-helmet-async";

const BlogContainer = ({ blog }) => {
  const [blogContent, setBlogContent] = useState(blog.blog_content ?? "");

  useEffect(() => {
    // if ((blog.blog_images ?? []).length > 1) {
    //   setBlogContent(
    //     (blog.blog_content ?? "").replace(
    //       "[INSERT IMAGE]",
    //       `<img src=${blog.blog_images[1]} alt=\"image2\" className=\"img-fluid mb-4\"/>`
    //     )
    //   );
    // } else {
    setBlogContent(blog.blog_content ?? "");
    // }
  }, [blog]);
  return (
    <>
      <Helmet>
        <title>{blog.blog_name}</title>
        <meta name="author" content={blog.blog_author} />
        <meta name="keywords" content={blog.blog_category} />
        <meta name="description" content={blog.blog_name} />
        <link rel="canonical" href={"/blogs/" + blog.blog_repr} />
      </Helmet>
      <div className="blog-container container mt-5">
        <main className="row">
          <div className="col-lg-8 mx-auto">
            {/* Article Header */}
            <div className="mb-4">
              <div className="d-flex align-items-center mb-4 blog-details">
                {/* <div className="me-2">
                <img
                  src="/api/placeholder/50/50"
                  alt="Joanna Goddard"
                  className="rounded-circle"
                  width="30"
                  height="30"
                />
              </div> */}
                <small className="text-muted">{blog.blog_date}</small>
                <small className="text-muted">{blog.blog_category}</small>
                <small className="text-muted">By {blog.blog_author}</small>
              </div>
              <h2 className="mb-2 fw-bold">{blog.blog_name}</h2>
              <img
                src={blog.blog_images[0]}
                alt="image1"
                className="img-fluid mb-4"
              />
            </div>

            {/* Article content */}
            <div className="article-content mb-5">{parse(blogContent)}</div>

            {/* Previous posts */}
            <div className="previous-posts mb-5">
              <h4 className="fw-bold mb-3">More Posts:</h4>
              <ul className="list-unstyled">
                {Blogs.filter((v) => v.blog_repr !== blog.blog_repr).map(
                  (value, index) => {
                    return (
                      <li key={index} className="mb-2">
                        <Link
                          to={"/blogs/" + value.blog_repr}
                          className="text-decoration-none"
                        >
                          {value.blog_name}
                        </Link>
                      </li>
                    );
                  }
                )}
              </ul>
            </div>
          </div>
        </main>

        {/* Comments section */}
        <section className="row mb-5">
          <div className="col-lg-8 mx-auto">
            <h4 className="fw-bold mb-4">Comments</h4>

            <div className="comment-form mb-5">
              <textarea
                className="form-control mb-3"
                rows="4"
                placeholder="Share your thoughts..."
              ></textarea>
              <button className="btn btn-primary">Post Comment</button>
            </div>
            <div className="comments">
              {(blog.blog_comments ?? []).map((c, i) => {
                return (
                  <div key={i} className="comment mb-4">
                    <div className="d-flex">
                      {/* <div className="me-3">
                      <img
                        src="/api/placeholder/50/50"
                        alt="Commenter"
                        className="rounded-circle"
                        width="40"
                        height="40"
                      />
                    </div> */}
                      <div>
                        <h5 className="mb-1 fw-bold">{c.name}</h5>
                        <p className="text-muted small">{c.date}</p>
                        <p>{c.comment}</p>
                        <button className="btn btn-sm btn-outline-secondary me-2">
                          Reply
                        </button>
                        <button className="btn btn-sm btn-outline-secondary">
                          Like
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default BlogContainer;
