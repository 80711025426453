import React, { useEffect, useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import logo from "../assets/images/milescorplogo.png";
import { Link } from "react-router-dom";
import Blogs from "../assets/files/blogs.json";

const NavBar = () => {
  const [activeLink, setActiveLink] = useState("home");
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 20) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    //   add event listener on mounting
    window.addEventListener("scroll", onScroll);

    //   remove it when component get removed from the dom
    return () => window.removeEventListener("scroll", onscroll);
  }, []);
  return (
    <>
      <Navbar
        // className={`fixed-top transition ${
        //   scrolled
        //     ? "bg-white bg-opacity-90 shadow-sm backdrop-blur"
        //     : "bg-transparent"
        // }`}
        style={{ zIndex: 1030 }}
        expand="lg"
        className={`transition ${
          scrolled
            ? "scrolled bg-white bg-opacity-90 shadow-sm backdrop-blur"
            : "bg-transparent"
        }
        `}
      >
        <Container>
          <Link to="/" className="navbar-brand">
            <img src={logo} alt="Logo" />
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav" className="bg-white">
            <Nav className="me-auto">
              <Nav.Link
                href="/#about"
                className={
                  activeLink === "about" ? "active navbar-link" : "navbar-link"
                }
                onClick={() => setActiveLink("about")}
              >
                Company
              </Nav.Link>
              <Nav.Link
                href="/#services"
                className={
                  activeLink === "services"
                    ? "active navbar-link"
                    : "navbar-link"
                }
                onClick={() => setActiveLink("services")}
              >
                Services
              </Nav.Link>
              <Nav.Link
                href="/#ourwork"
                className={
                  activeLink === "ourwork"
                    ? "active navbar-link"
                    : "navbar-link"
                }
                onClick={() => setActiveLink("ourwork")}
              >
                Our Work
              </Nav.Link>
              <Link to="/sme-salesforge" className="navbar-link nav-link">
                SME-SalesForge
              </Link>
              {/* <NavDropdown title="Blogs" id="collapsible-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
            </NavDropdown> */}
              <div className="nav-item dropdown">
                <Link to={"/"} className="navbar-link nav-link">
                  Blogs
                </Link>
                <div className="dropdown-menu">
                  {(Blogs ?? []).map((blog, index) => {
                    return (
                      <Link
                        key={index}
                        to={`/blogs/${blog.blog_repr}`}
                        data-rr-ui-dropdown-item
                        className="dropdown-item"
                      >
                        {blog.blog_name}
                      </Link>
                    );
                  })}
                </div>
              </div>
            </Nav>
            <span className="navbar-text">
              <button className="vvd">
                <Nav.Link href="/#contact">Get Started</Nav.Link>
              </button>
            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default NavBar;
