import React, { useEffect, useRef } from "react";

const Banner = () => {
  const titleRef = useRef(null);
  const subtitleRef = useRef(null);
  const buttonsRef = useRef(null);

  useEffect(() => {
    const observerOptions = {
      threshold: 0.1,
      rootMargin: "0px",
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("animate-fade-up");
          entry.target.classList.remove("opacity-0");
          observer.unobserve(entry.target);
        }
      });
    }, observerOptions);

    if (titleRef.current) {
      observer.observe(titleRef.current);
    }
    if (subtitleRef.current) {
      observer.observe(subtitleRef.current);
    }
    if (buttonsRef.current) {
      observer.observe(buttonsRef.current);
    }

    return () => observer.disconnect();
    // // eslint-disable-next-line
  }, []);

  return (
    <section className="position-relative min-vh-100 d-flex align-items-center justify-content-center pb-4 px-3 overflow-hidden">
      <div className="position-absolute inset-0" style={{ zIndex: -1 }}>
        <div
          className="position-absolute inset-0 bg-gradient-to-br from-white via-blue-50 to-slate-100"
          style={{
            background:
              "linear-gradient(to bottom right, white, #f0f7ff, #f8fafc)",
          }}
        ></div>
        <div
          className="position-absolute inset-0 opacity-5"
          style={{
            backgroundImage:
              "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjAiIGhlaWdodD0iNjAiIHZpZXdCb3g9IjAgMCA2MCA2MCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxnIGZpbGw9IiMzMjkzRkYiIGZpbGwtb3BhY2l0eT0iMC4wMiI+PHBhdGggZD0iTTM2IDM0aDR2MWgtNHYtMXptMC0yaDF2NWgtMXYtNXptMi0xMnY5aC0xdi04aC0yNHY4aC0xdi05aDI2em0tOS0xaDF2MmgtMXYtMnptNCAwdjFoLTJ2LTFoMnptLTIgMnYxaC0xdi0xaDF6bS0yLTNoMXYyaC0xdi0yek0yNiA3aDN2MWgtM3YtMXptLTEgMWgxdjJoLTF2LTJ6bTEgM3YxaC0xdi0xaDF6bS0yLTNoMXYyaC0xdi0yek0yMyA3aDJ2MWgtMnYtMXptMS0yaDF2MmgtMXYtMnptMC0zdjFoLTF2LTFoMXptLTMtMWgzdjJoLTJ2MWgtMXYtM3ptMCAzaDF2M2gtMXYtM3ptMSA1djFoLTJ2LTFoMnptLTQgMGg0djFoLTR2LTF6TTEzIDNoDB2N0g4di0xSDd2MWgtMnYxSDR2MWgxdjJINHYxaDFWOWgxdi0xaDJ2MmgtMXYxaDFWOGgxVjdoLTF2MWgtMVY3aC0xVjZoMVY1aC0ydjFoLTF2MUg0di0xaDF2LTFoMlYzaDJ2MWgxVjN6bS0xIDNoMXYyaC0xVjZ6TTkgM2gxdjJoLTF2MWgtMVY0aDF2LTF6bTEgNXYxaC0xdi0xaDF6TTUgOGgxdjFINXYtMXptNCAwYzAgMCAyIDAgMiAxaC0ydi0xeiIvPjwvZz48L2c+PC9zdmc+')",
          }}
        ></div>
      </div>

      <div className="container text-center" style={{ maxWidth: "1100px" }}>
        <h1 ref={titleRef} className="opacity-0 mb-4 fw-bold display-4">
          <span className="heading-text d-inline-block mb-2">
            Innovative Software
          </span>
          <br className="d-md-none" />
          <span className="heading-text d-inline-block">
            Development Solutions
          </span>
        </h1>

        <p
          ref={subtitleRef}
          className="opacity-0 mb-5 lead text-muted mx-auto"
          style={{ maxWidth: "700px" }}
        >
          We craft elegant, scalable, and high-performance applications tailored
          to your specific business needs, leveraging cutting-edge technologies.
        </p>

        <div
          ref={buttonsRef}
          className="opacity-0 d-flex flex-column flex-sm-row justify-content-center gap-3"
        >
          <a href="/#services" className="btn btn-primary px-4 py-2 fs-5">
            Explore Services
          </a>
          <a
            href="/#contact"
            className="btn btn-outline-secondary px-4 py-2 fs-5"
          >
            Contact Us
          </a>
        </div>
      </div>
    </section>
  );
};

export default Banner;
