import React from "react";
// import { Link } from "react-router-dom";
// import logo2 from "../assets/images/milescorplogo2.png";
// import number1 from "../assets/images/number1.png";
// import premium from "../assets/images/premium-quality.png";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer className="bg-light py-5">
      <div className="container">
        <div className="row gy-4">
          <div className="col-lg-5">
            <h3 className="fs-4 fw-semibold mb-3">MilesCorp</h3>
            <p className="text-muted mb-4" style={{ maxWidth: "400px" }}>
              Going the Extra Mile in creating innovative software solutions for
              forward-thinking businesses since 2020. Transforming ideas into
              exceptional digital experiences.
            </p>
            <div className="d-flex gap-3">
              <a href="/" className="text-muted">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-twitter"
                  viewBox="0 0 16 16"
                >
                  <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                </svg>
              </a>
              <a
                href="https://www.linkedin.com/in/mileslemi/"
                className="text-muted"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-linkedin"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                </svg>
              </a>
              <a href="https://github.com/Mileslemi" className="text-muted">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-github"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z" />
                </svg>
              </a>
            </div>
          </div>

          <div className="col-sm-6 col-md-4 col-lg-2">
            <h5 className="fs-6 fw-semibold mb-3">Services</h5>
            <ul className="list-unstyled">
              <li className="mb-2">
                <a
                  href="/#services"
                  className="text-decoration-none text-muted"
                >
                  Web Development
                </a>
              </li>
              <li className="mb-2">
                <a
                  href="/#services"
                  className="text-decoration-none text-muted"
                >
                  Mobile Development
                </a>
              </li>
              <li className="mb-2">
                <a
                  href="/#services"
                  className="text-decoration-none text-muted"
                >
                  Desktop Development
                </a>
              </li>
              <li className="mb-2">
                <a
                  href="/#services"
                  className="text-decoration-none text-muted"
                >
                  Cloud Solutions
                </a>
              </li>
              <li className="mb-2">
                <a
                  href="/#services"
                  className="text-decoration-none text-muted"
                >
                  DevOps
                </a>
              </li>
              <li className="mb-2">
                <a
                  href="/sme-salesforge"
                  className="text-decoration-none text-muted"
                >
                  SME SalesForge
                </a>
              </li>
            </ul>
          </div>

          <div className="col-sm-6 col-md-4 col-lg-2">
            <h5 className="fs-6 fw-semibold mb-3">Company</h5>
            <ul className="list-unstyled">
              <li className="mb-2">
                <a href="/#about" className="text-decoration-none text-muted">
                  About
                </a>
              </li>
              <li className="mb-2">
                <a href="/#ourwork" className="text-decoration-none text-muted">
                  Our Work
                </a>
              </li>
              <li className="mb-2">
                <a href="/" className="text-decoration-none text-muted">
                  Careers
                </a>
              </li>
              <li className="mb-2">
                <a href="/" className="text-decoration-none text-muted">
                  Blog
                </a>
              </li>
              <li className="mb-2">
                <a href="/#contact" className="text-decoration-none text-muted">
                  Contact
                </a>
              </li>
            </ul>
          </div>

          <div className="col-sm-6 col-md-4 col-lg-3">
            <h5 className="fs-6 fw-semibold mb-3">Contact Us</h5>
            <ul className="list-unstyled">
              <li className="mb-2 text-muted">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-geo-alt me-2"
                  viewBox="0 0 16 16"
                >
                  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                </svg>
                Base Road, Nairobi
              </li>
              <li className="mb-2">
                <a href="mailto:mileslemi@gmail.com" className="text-muted">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-envelope me-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                  </svg>
                  mileslemi@gmail.com
                </a>
              </li>
              <li className="mb-2">
                <a href="tel:+254706803305" className="text-muted">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-telephone me-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                  </svg>
                  (0706) 803-305
                </a>
              </li>
            </ul>
          </div>
        </div>

        <hr className="my-4" />

        <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
          <p className="text-muted mb-3 mb-md-0">
            © {year} MilesCorp. All rights reserved.
          </p>
          <div>
            <a href="/" className="text-decoration-none text-muted me-3">
              Privacy Policy
            </a>
            <a href="/" className="text-decoration-none text-muted me-3">
              Terms of Service
            </a>
            <a href="/" className="text-decoration-none text-muted">
              Cookies
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
